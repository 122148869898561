import { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { db } from 'services/firebase/firebaseConfig';
import { query, collection, orderBy, startAfter, limit, getDocs } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics'
import colors from "theme/colors";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import TiktokPixel from 'utils/tiktok_pixel';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const news_index = client.initIndex('firebase_news');

var last_search = "";

const News = (props) => {
	const navigate = useNavigate()
	const [news, setNews] = useState([]);
    const [skipNews, setSkipNews] = useState(0);
    const [limitNews, setLimitNews] = useState(15);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allNewsDisplayed, setAllNewsDisplayed] = useState(false);
	const [persist_scroll, set_persist_scroll] = useState(0);
	const [searchText, setSearchText] = useState("");
    const [init, setInit] = useState(false)

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Noticias_Web", 
			firebase_screen_class: "Noticias_Web"
		});
		TiktokPixel.pageView();
		let storageItem = sessionStorage.getItem("news_persist")
        let news_persist = JSON.parse(storageItem)
        if(news_persist){
			//console.log("noticias: ", news_persist.news)
            set_persist_scroll(news_persist.scrollY)
            setSkipNews(new Date(news_persist.skipNews))
            setNews(news_persist.news)
            setLoading(news_persist.loading)
            setLoadingMore(news_persist.loadingMore)
            setAllNewsDisplayed(news_persist.allNewsDisplayed)
			setSearchText(news_persist.searchText)
            sessionStorage.removeItem("news_persist")
        }else{
            getNews()
        }
		setInit(true)
	},[])

	useEffect(()=>{
		if(init == true){
			if(persist_scroll && news.length > 0){
				window.scrollTo(0, persist_scroll)
				set_persist_scroll(0)
			}else if(last_search != searchText && String(searchText).trim().length == 0 && news.length == 0){
				getNews()
			}
		}
    },[news, persist_scroll], init)

	const getNews = async ()=> {
		setLoadingMore(true)
		try {
			let docSnap = null
            if(skipNews){
                const collRef = collection(db, constants.mode + "news")
                const docsRef = query(collRef, orderBy("createdAt","desc"), startAfter(skipNews), limit(limitNews))
                docSnap = await getDocs(docsRef);
            }else{
                const collRef = collection(db, constants.mode + "news")
                const docsRef = query(collRef, orderBy("createdAt","desc"), limit(limitNews))
                docSnap = await getDocs(docsRef);
            }
            if(docSnap.docs.length > 1){
                if(docSnap.docs.length < 15){
                    setAllNewsDisplayed(true)
                }
                setSkipNews(docSnap.docs[docSnap.docs.length-1].data().createdAt.toDate())
                var moreNews = []
                await docSnap.forEach((doc) => {
                    const itemNew = {
                        uid: doc.id,
						publicDate: moment(doc.data().createdAt.toDate()).format('DD/MM/YYYY'),
                        ...doc.data()
                    }
                    moreNews.push(itemNew)
                });
                setNews([...news, ...moreNews])
				//console.log("noticias: ", moreNews)
                setLoading(false)
                setLoadingMore(false)
            }else{
                setAllNewsDisplayed(true)
                setLoading(false)
                setLoadingMore(false)
            }
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("error: ", error)
        }
	}

	const moreNews = async () => {
        if(loadingMore == false){
            //console.log("SKIP: ", skipProducts)
            getNews()
        }
    }

	const handleSubmit = async (event) => {
        setSearchText(event.target.value)
        if(last_search != event.target.value){
            if(String(event.target.value).trim().length > 2){
                last_search = event.target.value
                setLoading(true)
                handleSearch()
            }else if(String(event.target.value).trim().length == 0){
                setSkipNews(null)
                setNews([])
                setAllNewsDisplayed(false)
                setLoading(true)
            }
        }
    }

    const handleSearch = () => {
		try {
			let filters = ``
			news_index.search(searchText, {
				filters: filters,
				ruleContexts: "client"
			}).then(({ hits }) => {
				//console.log("Hits: ", hits.length)
				setLoading(false)
				setNews([])
				setNews(prev => [...hits])
				console.log("Hits: ", news)
                setAllNewsDisplayed(true)
			});
		} catch (error) {
			console.log("Error: ", error)
			setLoading(false)
		}
	}

    const openNew = async (item) => {
		let news_persist = {
            news,
            skipNews,
            loading,
            loadingMore,
            allNewsDisplayed,
            scrollY: window.scrollY,
			searchText
        }
        sessionStorage.setItem("news_persist", JSON.stringify(news_persist))
		if(item.objectID){
            navigate(`/new/${item.objectID}`)
        }else{
            navigate(`/new/${item.uid}`)
        }
    }

	const _renderHtmlText = (text) => {
		return text.replace(/<(?:.|\n)*?>/gm, '');
	}

	return (
		<Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
			<Box sx={{width: "100%", position: "sticky", top: "8rem", backgroundColor: "white", zIndex: 1000, paddingTop: "16px"}}>
                <Typography variant='title3'>
                    Noticias
                </Typography>
            </Box>
			<Input
                placeholder='Buscar noticias'
                sx={{ width: "100%", height: "32px", paddingLeft: "16px", paddingRight: "16px", border: "1px solid " + colors.medium_light_gray, borderRadius: "16px" }}
                disableUnderline={true}
                onChange={(event)=>handleSubmit(event)}
                value={searchText}
            />
			{loading == true ?
				<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>
                        Cargando...
                    </Typography>
                </Box>
			:
				news && news.length > 0 ?
					<Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
						{news.map((itemNew, index)=> {
							if(itemNew){
								return  <div key={index}>
											<Grid item onClick={()=>openNew(itemNew)} sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", minHeight: "132px", marginTop: "4px", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "4px" }}>
												<Grid item xs={3} sm={2} md={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: "8px", overflow: "hidden", position: "relative" }}>
													{itemNew.images ?
														<img
															alt="img"
															src={itemNew.images[0] + imgSuffix.md} 
															style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
														/> 
													: (itemNew.legacy_images ? 
														<img
															alt="img"
															src={itemNew.legacy_images[0] + "480x480"} 
															style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
														/> 
													: "")}
												</Grid>
												<Grid item xs={9} sm={10} md={10} sx={{ marginLeft: "16px", cursor: "pointer" }}> 
													<Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{itemNew.name}</Typography>
													<Typography 
														variant="body2"
														sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, textOverflow: "ellipsis", paddingTop: 0, marginTop: 0, padding: 0, margin: 0}}
													>
														{_renderHtmlText(itemNew.html)}
														{/* <span style={{paddingTop: 0, marginTop: 0}}>
															{ReactHtmlParser(itemNew.html)}
														</span> */}
													</Typography>
													<Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
														<Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
															<Typography variant="body2">Vía:&nbsp;</Typography>
															<Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{itemNew.source}</Typography>
														</Grid>
														{itemNew.publicDate ?
															<Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{itemNew.publicDate}</Typography>
														:
															<Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{moment(itemNew.createdAt).format('DD/MM/YYYY')}</Typography>
														}
													</Grid>
												</Grid>
											</Grid>
											<div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
										</div>
							}else{
								return  <Box>
											<Typography>Noticia no disponible</Typography>
										</Box>
							}
						})}
						{loadingMore == true ? 
							<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
								<Typography>
									Cargando...
								</Typography>
							</Box>
						:
							!allNewsDisplayed ?
								<Button sx={{ marginTop: "8px" }} onClick={()=>moreNews()}>
									<Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
								</Button>
							:null
						}
					</Grid>
				:
					<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
						<Typography>
							No hay noticias
						</Typography>
					</Box>
			}
		</Container>
	)
}

News.layout = "L1";
export default News;